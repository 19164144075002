// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    HomeOutlined,
    MenuBookOutlined,
    NotificationsNoneOutlined,
    CalendarMonthOutlined,
    RateReviewOutlined,
    InventoryOutlined,
    InfoOutlined,
    ClassOutlined,
    SupervisorAccountOutlined,
    FamilyRestroomOutlined,
    BadgeOutlined,
    CurrencyRupeeOutlined,
    Security,
    Storage,
    LockOpen,
    DirectionsBus
} from '@mui/icons-material';

// constant
const icons = {
    HomeOutlined,
    MenuBookOutlined,
    NotificationsNoneOutlined,
    RateReviewOutlined,
    InventoryOutlined,
    CalendarMonthOutlined,
    InfoOutlined,
    ClassOutlined,
    SupervisorAccountOutlined,
    FamilyRestroomOutlined,
    BadgeOutlined,
    CurrencyRupeeOutlined,
    Security,
    LockOpen,
    Storage,
    DirectionsBus
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const managments = {
    id: 'managments',
    type: 'group',
    children: [
        // {
        //     id: 'home',
        //     title: <FormattedMessage id="Home" />,
        //     type: 'item',
        //     url: '/home',
        //     icon: icons.HomeOutlined,
        //     breadcrumbs: true
        // },
        {
            id: 'info',
            title: <FormattedMessage id="Info" />,
            type: 'item',
            url: '/home',
            icon: icons.InfoOutlined,
            breadcrumbs: false
        },
        {
            id: 'classes',
            title: <FormattedMessage id="Classes" />,
            type: 'item',
            url: '/classes',
            icon: icons.ClassOutlined,
            breadcrumbs: false
        },
        {
            id: 'attendance',
            title: <FormattedMessage id="Attendance" />,
            type: 'item',
            url: '/attendance',
            icon: icons.CalendarMonthOutlined,
            breadcrumbs: false
        },
        {
            id: 'parents',
            title: <FormattedMessage id="Parents" />,
            type: 'item',
            url: '/parents',
            icon: icons.FamilyRestroomOutlined,
            breadcrumbs: false
        },
        {
            id: 'notice',
            title: <FormattedMessage id="Notice" />,
            type: 'item',
            url: '/notice',
            icon: icons.NotificationsNoneOutlined,
            breadcrumbs: false
        },
        {
            id: 'exams',
            title: <FormattedMessage id="Exams" />,
            type: 'item',
            url: '/exams',
            icon: icons.RateReviewOutlined,
            breadcrumbs: false
        },
        {
            id: 'results',
            title: <FormattedMessage id="Results" />,
            type: 'item',
            url: '/results',
            icon: icons.InventoryOutlined,
            breadcrumbs: false
        },
        {
            id: 'teachers',
            title: <FormattedMessage id="Teachers" />,
            type: 'item',
            url: '/teachers',
            icon: icons.SupervisorAccountOutlined,
            breadcrumbs: false
        },
        {
            id: 'staffs',
            title: <FormattedMessage id="Staffs" />,
            type: 'item',
            url: '/staffs',
            icon: icons.BadgeOutlined,
            breadcrumbs: false
        },
        {
            id: 'fees',
            title: <FormattedMessage id="Fees" />,
            type: 'item',
            url: '/fees',
            icon: icons.CurrencyRupeeOutlined,
            breadcrumbs: false
        },
        {
            id: 'permission',
            title: <FormattedMessage id="Permission" />,
            type: 'item',
            url: '/permission',
            // icon: icons.Security,
            icon: icons.LockOpen,
            breadcrumbs: false
        },
        {
            id: 'records',
            title: <FormattedMessage id="Records" />,
            type: 'collapse',
            icon: icons.Storage,
            children: [
                {
                    id: 'new-record',
                    title: <FormattedMessage id="New Record" />,
                    type: 'item',
                    url: '/new-record',
                    breadcrumbs: false
                },
                {
                    id: 'update-record',
                    title: <FormattedMessage id="Update Record" />,
                    type: 'item',
                    url: '/update-record',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'transport',
            title: <FormattedMessage id="Transport" />,
            type: 'collapse',
            icon: icons.DirectionsBus,
            children: [
                {
                    id: 'vehicles',
                    title: <FormattedMessage id="Vehicles" />,
                    type: 'item',
                    url: '/vehicles',
                    breadcrumbs: false
                },
                {
                    id: 'vehicle-routes',
                    title: <FormattedMessage id="Routes" />,
                    type: 'item',
                    url: '/vehicle-routes',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default managments;
