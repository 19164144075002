import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, Card, Grid, InputAdornment, OutlinedInput, Popper } from '@mui/material';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from '@tabler/icons';
import { fontSize, shouldForwardProp, width } from '@mui/system';

// styles
const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: 434,
    marginLeft: 0,
    margin: '0px 0px',
    height: 38,
    paddingLeft: 16,
    paddingRight: 8,
    '& input': {
        background: 'transparent !important'
        // paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        // marginLeft: 4,
        background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
    }
}));

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue }) => {
    const theme = useTheme();

    return (
        <OutlineInputStyle
            className="Abhishekh2"
            id="input-search-header"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Search"
            startAdornment={
                <InputAdornment position="start">
                    <IconSearch stroke={1.5} size="16px" color={theme.palette.grey[500]} />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    <HeaderAvatarStyle variant="rounded" sx={{ height: 28, width: 28 }}>
                        <IconAdjustmentsHorizontal stroke={1.5} size="20px" />
                    </HeaderAvatarStyle>
                </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{ 'aria-label': 'weight' }}
        />
    );
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
    const theme = useTheme();
    const [value, setValue] = useState('');

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none', width: '55%', marginLeft: '2%', marginRight: '2%' } }} className="Abhishek7">
                <Card
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark[900] : '#fff',
                        [theme.breakpoints.down('sm')]: {
                            border: 0,
                            boxShadow: 'none'
                        }
                    }}
                >
                    <Box sx={{ p: 0 }} className="Abhishek5">
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item xs>
                                <MobileSearch value={value} setValue={setValue} />
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </>
    );
};

export default SearchSection;
