import React, { lazy, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, IconButton, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
// import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';

// assets
import { IconChevronRight } from '@tabler/icons';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import useMenuItems from 'hooks/useMenuItems';
import useAuth from 'hooks/useAuth';
import Loadable from 'ui-component/Loadable';

const Pricing = Loadable(lazy(() => import('views/common/Pricing')));

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, defaultRole, isSx }) => ({
    ...(isSx &&
        (defaultRole !== 'securitygaurd' || defaultRole !== 'organization') &&
        defaultRole !== 'accountant' &&
        defaultRole !== 'conductor' &&
        theme.typography.mainContentSx),
    ...(!isSx &&
        (defaultRole !== 'securitygaurd' || defaultRole !== 'organization') &&
        defaultRole !== 'accountant' &&
        defaultRole !== 'conductor' &&
        theme.typography.mainContentSm),
    ...((defaultRole === 'securitygaurd' ||
        defaultRole === 'accountant' ||
        defaultRole === 'conductor' ||
        defaultRole === 'organization') &&
        theme.typography.mainContentOnlyHeader),
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            // marginLeft: -(drawerWidth - 20),
            // New Drawer Btton Design 1
            marginLeft: -(drawerWidth - 0),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            // New Drawer Btton Design 1
            // marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '12px',
            marginRight: '0px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const { defaultRole, isLoggedIn, user } = useAuth();
    const navigation = useMenuItems();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(defaultRole === 'free' && isLoggedIn);

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();

    const handleClose = () => {
        setOpen(!open);
    };

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    );

    return (
        <>
            <Box
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    mb:
                        defaultRole === 'organization' ||
                        defaultRole === 'securitygaurd' ||
                        defaultRole === 'accountant' ||
                        defaultRole === 'conductor'
                            ? '0px'
                            : '52px'
                }}
            >
                <CssBaseline />
                {/* header */}
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <AppBar
                        enableColorOnDark
                        position="fixed"
                        color="inherit"
                        elevation={0}
                        sx={{
                            bgcolor: theme.palette.background.default,
                            transition: drawerOpen ? theme.transitions.create('width') : 'none'
                        }}
                    >
                        {header}
                    </AppBar>
                </Box>

                {/* main content */}
                <Main
                    theme={theme}
                    open={drawerOpen}
                    defaultRole={defaultRole}
                    isSx={matchDownSM}
                    sx={{ width: 'calc(100% - 0px) !important' }}
                >
                    {/* breadcrumb */}
                    {container && (
                        <Container maxWidth="lg">
                            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                            <Outlet />
                        </Container>
                    )}
                    {!container && (
                        <>
                            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                            <Outlet />
                        </>
                    )}
                </Main>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {defaultRole === 'organization' ||
                defaultRole === 'securitygaurd' ||
                defaultRole === 'accountant' ||
                defaultRole === 'conductor' ? (
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <AppBar
                            enableColorOnDark
                            position="fixed"
                            color="inherit"
                            elevation={0}
                            sx={{
                                bgcolor: theme.palette.background.default,
                                transition: drawerOpen ? theme.transitions.create('width') : 'none'
                            }}
                        >
                            {header}
                        </AppBar>
                    </Box>
                ) : null}
                <Box
                    sx={{
                        display: 'flex',
                        mt:
                            defaultRole === 'organization' ||
                            defaultRole === 'securitygaurd' ||
                            defaultRole === 'accountant' ||
                            defaultRole === 'conductor'
                                ? '52px'
                                : '0px'
                    }}
                >
                    {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}> */}
                    <CssBaseline />
                    {/* header */}

                    {/* drawer */}
                    {defaultRole !== 'organization' &&
                    defaultRole !== 'securitygaurd' &&
                    defaultRole !== 'accountant' &&
                    defaultRole !== 'conductor' ? (
                        <Sidebar />
                    ) : null}

                    {/* main content */}
                    <Main theme={theme} open={drawerOpen} defaultRole={defaultRole} isSx={matchDownSM} sx={{ mt: 0 }}>
                        {/* breadcrumb */}
                        {container && (
                            <Container maxWidth="lg">
                                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                                <Outlet />
                            </Container>
                        )}
                        {!container && (
                            <>
                                <Box
                                    sx={{
                                        display: {
                                            sm: 'flex',
                                            alignItems: 'center',
                                            height: 'calc(100vh - 80px)',
                                            position: 'absolute'
                                        }
                                    }}
                                >
                                    <IconButton
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.mediumAvatar,
                                            overflow: 'hidden',
                                            background:
                                                // eslint-disable-next-line no-nested-ternary
                                                theme.palette.mode === 'dark' ? theme.palette.dark.main : drawerOpen ? '#e8eaf6' : 'white',
                                            color:
                                                theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                            '&:hover': {
                                                background:
                                                    theme.palette.mode === 'dark'
                                                        ? theme.palette.secondary.main
                                                        : theme.palette.secondary.dark,
                                                color:
                                                    theme.palette.mode === 'dark'
                                                        ? theme.palette.secondary.light
                                                        : theme.palette.secondary.light
                                            },
                                            position: 'fixed',
                                            zIndex: 1200,
                                            width: 16,
                                            borderRadius: !drawerOpen ? '0px 45px 45px 0px' : '45px 0px 0px 45px',
                                            height: 60
                                        }}
                                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                                        color="inherit"
                                        style={{ marginLeft: !drawerOpen ? -20 : -36 }}
                                    >
                                        {drawerOpen && <ArrowLeft stroke={1.5} size="35px" />}
                                        {!drawerOpen && <ArrowRight stroke={1.5} size="35px" />}
                                    </IconButton>
                                </Box>
                                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                                <Outlet />
                            </>
                        )}
                    </Main>
                    {defaultRole === 'free' && user.isEmailVerfied && <Pricing open={open} handleClose={handleClose} />}
                </Box>
            </Box>
        </>
    );
};

export default MainLayout;
