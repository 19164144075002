import React from 'react';

// project improts
import useConfig from 'hooks/useConfig';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import {
    AddCircle,
    AddTask,
    AutoStories,
    BadgeOutlined,
    CalendarMonthOutlined,
    ClassOutlined,
    CurrencyRupeeOutlined,
    DirectionsBus,
    InventoryOutlined,
    LockOpen,
    RateReviewOutlined,
    Route,
    SupervisorAccountOutlined
} from '@mui/icons-material';
import { createTheme } from '@mui/system';
import useAuth from 'hooks/useAuth';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { setSelectedMobileMenuItem } from 'store/slices/common';

// ================================|| UI LIST - SELECTED LIST ||================================ //

const theme = createTheme({
    palette: {
        background: {
            paper: '#fff'
        },
        text: {
            primary: '#173A5E',
            secondary: '#46505A'
        },
        action: {
            active: '#001E3C'
        },
        success: {
            dark: '#009688'
        }
    }
});

const listTextColor = {
    fontWeight: 500,
    fontSize: '0.875rem',
    color: 'white',
    fontFamily: 'Roboto,sans-serif',
    textAlign: 'center'
};

export default function SelectedListItem({ handleTooltipOpen }) {
    const { defaultRole } = useAuth();
    const { borderRadius } = useConfig();
    const { selectedMobileMenuItem } = useSelector((state) => state.common);
    const dispatch = useDispatch();

    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const handleListItemClick = (event, index) => {
        // setSelectedIndex(index);
        dispatch(setSelectedMobileMenuItem(index));
        handleTooltipOpen();
    };

    const itemTarget = '_self';
    const listItemProps = (link) => ({
        component: React.forwardRef((props, ref) => <Link ref={ref} {...props} to={link} target={itemTarget} />)
    });

    const managementList = () => (
        <>
            <ListItemButton
                {...listItemProps('/parents')}
                selected={selectedMobileMenuItem === 0}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <ListItemIcon>
                    <ClassOutlined />
                </ListItemIcon>
                <ListItemText primary="Parents" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/teachers')}
                selected={selectedMobileMenuItem === 1}
                onClick={(event) => handleListItemClick(event, 1)}
            >
                <ListItemIcon>
                    <SupervisorAccountOutlined />
                </ListItemIcon>
                <ListItemText primary="Teachers" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/exams')}
                selected={selectedMobileMenuItem === 2}
                onClick={(event) => handleListItemClick(event, 2)}
            >
                <ListItemIcon>
                    <RateReviewOutlined />
                </ListItemIcon>
                <ListItemText primary="Exams" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/results')}
                selected={selectedMobileMenuItem === 3}
                onClick={(event) => handleListItemClick(event, 3)}
            >
                <ListItemIcon>
                    <InventoryOutlined />
                </ListItemIcon>
                <ListItemText primary="Results" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/staffs')}
                selected={selectedMobileMenuItem === 4}
                onClick={(event) => handleListItemClick(event, 4)}
            >
                <ListItemIcon>
                    <BadgeOutlined />
                </ListItemIcon>
                <ListItemText primary="Staffs" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/attendance')}
                selected={selectedMobileMenuItem === 5}
                onClick={(event) => handleListItemClick(event, 5)}
            >
                <ListItemIcon>
                    <CalendarMonthOutlined />
                </ListItemIcon>
                <ListItemText primary="Attendance" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/permission')}
                selected={selectedMobileMenuItem === 6}
                onClick={(event) => handleListItemClick(event, 6)}
            >
                <ListItemIcon>
                    <LockOpen />
                </ListItemIcon>
                <ListItemText primary="Permission" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/new-record')}
                selected={selectedMobileMenuItem === 7}
                onClick={(event) => handleListItemClick(event, 7)}
            >
                <ListItemIcon>
                    <AddCircle />
                </ListItemIcon>
                <ListItemText primary="New Record" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/update-record')}
                selected={selectedMobileMenuItem === 8}
                onClick={(event) => handleListItemClick(event, 8)}
            >
                <ListItemIcon>
                    <AddTask />
                </ListItemIcon>
                <ListItemText primary="Update Record" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/vehicles')}
                selected={selectedMobileMenuItem === 9}
                onClick={(event) => handleListItemClick(event, 9)}
            >
                <ListItemIcon>
                    <DirectionsBus />
                </ListItemIcon>
                <ListItemText primary="Vehicles" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/vehicle-routes')}
                selected={selectedMobileMenuItem === 10}
                onClick={(event) => handleListItemClick(event, 10)}
            >
                <ListItemIcon>
                    <Route />
                </ListItemIcon>
                <ListItemText primary="Routes" />
            </ListItemButton>
        </>
    );

    const studentList = () => (
        <>
            <ListItemButton
                {...listItemProps('/exam')}
                selected={selectedMobileMenuItem === 0}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <ListItemIcon>
                    <RateReviewOutlined />
                </ListItemIcon>
                <ListItemText primary="Exams" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/result')}
                selected={selectedMobileMenuItem === 1}
                onClick={(event) => handleListItemClick(event, 1)}
            >
                <ListItemIcon>
                    <InventoryOutlined />
                </ListItemIcon>
                <ListItemText primary="Results" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/fees')}
                selected={selectedMobileMenuItem === 2}
                onClick={(event) => handleListItemClick(event, 2)}
            >
                <ListItemIcon>
                    <CurrencyRupeeOutlined />
                </ListItemIcon>
                <ListItemText primary="Fees" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/subjects')}
                selected={selectedMobileMenuItem === 3}
                onClick={(event) => handleListItemClick(event, 3)}
            >
                <ListItemIcon>
                    <AutoStories />
                </ListItemIcon>
                <ListItemText primary="Subjects" />
            </ListItemButton>
        </>
    );

    const teacherList = () => (
        <>
            <ListItemButton
                {...listItemProps('/exams')}
                selected={selectedMobileMenuItem === 0}
                onClick={(event) => handleListItemClick(event, 0)}
            >
                <ListItemIcon>
                    <RateReviewOutlined />
                </ListItemIcon>
                <ListItemText primary="Exams" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/results')}
                selected={selectedMobileMenuItem === 1}
                onClick={(event) => handleListItemClick(event, 1)}
            >
                <ListItemIcon>
                    <InventoryOutlined />
                </ListItemIcon>
                <ListItemText primary="Results" />
            </ListItemButton>
            <ListItemButton
                {...listItemProps('/fees')}
                selected={selectedMobileMenuItem === 2}
                onClick={(event) => handleListItemClick(event, 2)}
            >
                <ListItemIcon>
                    <CurrencyRupeeOutlined />
                </ListItemIcon>
                <ListItemText primary="Fees" />
            </ListItemButton>
        </>
    );

    return (
        <List
            sx={{
                p: 0,
                '& .MuiListItemButton-root:nth-child(1)': { mt: 0 },
                '& .MuiListItemButton-root': {
                    // bgcolor: '#1c5694',
                    bgcolor: '#3F51B5',
                    p: 0.35,
                    mt: '7px',
                    // borderRadius: `${borderRadius}px`,
                    borderRadius: `2px`,
                    ':hover': {
                        // bgcolor: '#c8deec',
                        bgcolor: '#bdc3e8',
                        '.MuiListItemIcon-root .MuiSvgIcon-root': {
                            // color: '#1c5694',
                            color: '#3F51B5',
                            fontSize: 24,
                            pl: 0.3
                        },
                        '.MuiListItemText-primary': {
                            // color: '#1c5694'
                            color: '#3F51B5'
                        }
                    },
                    '&.Mui-selected': {
                        ':hover': {
                            // bgcolor: '#c8deec'
                            bgcolor: '#bdc3e8'
                        },
                        // bgcolor: '#c8deec',
                        bgcolor: '#bdc3e8',
                        '.MuiListItemText-primary': {
                            // color: '#1c5694'
                            color: '#3F51B5'
                        },
                        '.MuiListItemIcon-root .MuiSvgIcon-root': {
                            // color: '#1c5694'
                            color: '#3F51B5'
                        }
                    },
                    '.MuiListItemText-primary': {
                        color: 'white'
                    },
                    '.MuiListItemIcon-root .MuiSvgIcon-root': {
                        color: 'white',
                        fontSize: 24,
                        pl: 0.3
                    },
                    '.MuiListItemIcon-root': {
                        minWidth: 28
                    }
                }
            }}
        >
            {defaultRole === 'managment' && managementList()}
            {defaultRole === 'teacher' && teacherList()}
            {defaultRole === 'student' && studentList()}
            {/* <ListItemButton selected={selectedMobileMenuItem === 0} onClick={(event) => handleListItemClick(event, 0)}>
                <ListItemIcon>
                    <HomeTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Sample Page" />
            </ListItemButton>
            <ListItemButton selected={selectedMobileMenuItem === 1} onClick={(event) => handleListItemClick(event, 1)}>
                <ListItemIcon>
                    <DescriptionTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Elements" />
            </ListItemButton>
            <ListItemButton selected={selectedMobileMenuItem === 2} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <ListAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Page Layouts" />
            </ListItemButton> */}
            {/* <ListItemButton selected={selectedMobileMenuItem === 3} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <ListAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Page Layouts" />
            </ListItemButton>
            <ListItemButton selected={selectedMobileMenuItem === 4} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <ListAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Page Layouts" />
            </ListItemButton>
            <ListItemButton selected={selectedMobileMenuItem === 5} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <ListAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Page Layouts" />
            </ListItemButton>
            <ListItemButton selected={selectedMobileMenuItem === 3} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <ListAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Page Layouts" />
            </ListItemButton>
            <ListItemButton selected={selectedMobileMenuItem === 4} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <ListAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Page Layouts" />
            </ListItemButton>
            <ListItemButton selected={selectedMobileMenuItem === 5} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <ListAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Page Layouts" />
            </ListItemButton>
            <ListItemButton selected={selectedMobileMenuItem === 3} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <ListAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Page Layouts" />
            </ListItemButton>
            <ListItemButton selected={selectedMobileMenuItem === 4} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <ListAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Page Layouts" />
            </ListItemButton>
            <ListItemButton selected={selectedMobileMenuItem === 5} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <ListAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Page Layouts" />
            </ListItemButton>
            <ListItemButton selected={selectedMobileMenuItem === 3} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <ListAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Page Layouts" />
            </ListItemButton>
            <ListItemButton selected={selectedMobileMenuItem === 4} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <ListAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Page Layouts" />
            </ListItemButton>
            <ListItemButton selected={selectedMobileMenuItem === 5} onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                    <ListAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Page Layouts" />
            </ListItemButton> */}
        </List>
    );
}
