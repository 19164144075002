/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    idToken: '',
    role: 'pbxmgmtfree',
    uid: ''
};

export const commonWPSlice = createSlice({
    name: 'managment',
    initialState,
    reducers: {
        setIdToken: (state, action) => {
            state.idToken = action.payload;
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setUid: (state, action) => {
            state.uid = action.payload;
        }
    }
});

export const { setIdToken, setRole, setUid } = commonWPSlice.actions;

export default commonWPSlice.reducer;
