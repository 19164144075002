import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const Home = Loadable(lazy(() => import('views/managment/Home')));
const Info = Loadable(lazy(() => import('views/managment/Info')));
const Teachers = Loadable(lazy(() => import('views/managment/Teachers')));
const Exams = Loadable(lazy(() => import('views/managment/Exams')));
const Results = Loadable(lazy(() => import('views/managment/Results')));
const Parents = Loadable(lazy(() => import('views/managment/Parents')));
const Staffs = Loadable(lazy(() => import('views/managment/Staffs')));
const Fees = Loadable(lazy(() => import('views/managment/Fees')));
const Notice = Loadable(lazy(() => import('views/managment/Notice')));
const Attendance = Loadable(lazy(() => import('views/managment/Attendance')));
const Classes = Loadable(lazy(() => import('views/managment/Classes')));
const Profile = Loadable(lazy(() => import('views/common/Profile')));
const Account = Loadable(lazy(() => import('views/common/Account')));
const NewRecord = Loadable(lazy(() => import('views/managment/Record/NewRecord')));
const UpdateRecord = Loadable(lazy(() => import('views/managment/Record/UpdateRecord')));
const StudentDetails = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewStudent/StudentDetails')));
const FatherDetails = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewStudent/FatherDetails')));
const MotherDetails = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewStudent/MotherDetails')));
const GaurdianDetails = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewStudent/GaurdianDetails')));
const ClassDetails = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewStudent/ClassDetails')));
// const NewClasses = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewClasses')));
// const NewTeacher = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewTeacher')));
const SchoolClassStudents = Loadable(lazy(() => import('views/teacher/Students')));
const SchoolClassAttendance = Loadable(lazy(() => import('views/teacher/Attendance')));

const Gaurdians = Loadable(lazy(() => import('views/managment/Parents/Gaurdian')));
const Mothers = Loadable(lazy(() => import('views/managment/Parents/Mother')));
const Fathers = Loadable(lazy(() => import('views/managment/Parents/Father')));
const FeesDetails = Loadable(lazy(() => import('views/managment/Fees/FeesStatusByMonths')));
// const ResultsByClass = Loadable(lazy(() => import('views/managment/Results/ResultsByClass')));
const TeacherSubAndMark = Loadable(lazy(() => import('views/teacher/Results/ResultSubAndMark')));
const Permission = Loadable(lazy(() => import('views/managment/Permission')));
const Vehicles = Loadable(lazy(() => import('views/managment/Transport/Vehicles')));
const VehicleRoutes = Loadable(lazy(() => import('views/managment/Transport/VehicleRoutes')));
const Subjects = Loadable(lazy(() => import('views/managment/Classes/Subjects')));

// ==============================|| MAIN ROUTING ||============================== //

const ManagmentRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // {
        //     path: '/home',
        //     element: <Home />
        // },
        {
            path: '/home',
            element: <Info />
        },
        {
            path: '/classes',
            element: <Classes />
        },
        {
            path: '/classes/subjects',
            element: <Subjects />
        },
        {
            path: '/teachers',
            element: <Teachers />
        },
        {
            path: '/exams',
            element: <Exams />
        },
        {
            path: '/results',
            element: <Results />
        },
        {
            path: '/parents',
            element: <Parents />
        },
        {
            path: '/staffs',
            element: <Staffs />
        },
        {
            path: '/fees',
            element: <Fees />
        },
        {
            path: '/notice',
            element: <Notice />
        },
        {
            path: '/attendance',
            element: <Attendance />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/new-record',
            element: <NewRecord />,
            children: [
                {
                    path: 'student-details',
                    element: <StudentDetails />
                },
                {
                    path: 'father-details',
                    element: <FatherDetails />
                },
                {
                    path: 'mother-details',
                    element: <MotherDetails />
                },
                {
                    path: 'guardian-details',
                    element: <GaurdianDetails />
                },
                {
                    path: 'class-details',
                    element: <ClassDetails />
                }
            ]
        },
        {
            path: '/update-record',
            element: <UpdateRecord />
            // children: [
            //     {
            //         path: 'student-details',
            //         element: <StudentDetails />
            //     },
            //     {
            //         path: 'father-details',
            //         element: <FatherDetails />
            //     },
            //     {
            //         path: 'mother-details',
            //         element: <MotherDetails />
            //     },
            //     {
            //         path: 'guardian-details',
            //         element: <GaurdianDetails />
            //     },
            //     {
            //         path: 'class-details',
            //         element: <ClassDetails />
            //     }
            // ]
        },
        {
            path: '/classes/students',
            element: <SchoolClassStudents />
        },
        {
            path: '/attendance/class',
            element: <SchoolClassAttendance />
        },
        {
            path: '/parents/gaudians',
            element: <Gaurdians />
        },
        {
            path: '/parents/mothers',
            element: <Mothers />
        },
        {
            path: '/parents/fathers',
            element: <Fathers />
        },
        // {
        //     path: '/parents/fathers',
        //     element: <Fathers />
        // },
        {
            path: '/fees/fees-details',
            element: <FeesDetails />
        },
        // {
        //     path: '/results/class',
        //     element: <ResultsByClass />
        // },
        {
            path: '/results/class/subject-and-marks',
            element: <TeacherSubAndMark />
        },
        {
            path: '/permission',
            element: <Permission />
        },
        {
            path: '/vehicles',
            element: <Vehicles />
        },
        {
            path: '/vehicles/students',
            element: <FeesDetails />
        },
        {
            path: '/vehicle-routes',
            element: <VehicleRoutes />
        },
        {
            path: '/vehicle-routes/vehicles',
            element: <Vehicles />
        },
        {
            path: '/vehicle-routes/vehicles/students',
            element: <FeesDetails />
        }
    ]
};

export default ManagmentRoutes;
