import React from 'react';
import useAuth from './useAuth';
import parent from '../menu-items/Parent';
import student from '../menu-items/Student';
import teacher from '../menu-items/Teacher';
import managment from 'menu-items/Managment';
import anonymoususer from 'menu-items/AnonymousUser';

function useMenuItems() {
    const { defaultRole, isLoggedIn, user } = useAuth();
    if (defaultRole === 'anon' || !user.isEmailVerfied) {
        return {
            items: [anonymoususer]
        };
    }
    if (defaultRole === 'managment' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [managment]
        };
    }
    if (defaultRole === 'teacher' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [teacher]
        };
    }
    if (defaultRole === 'parent' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [parent]
        };
    }
    if (defaultRole === 'student' && isLoggedIn !== false && user.isEmailVerfied) {
        return {
            items: [student]
        };
    }
    return {
        items: [anonymoususer]
    };
}

export default useMenuItems;
