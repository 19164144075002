// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import accountReducer from './accountReducer';
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import studentReducer from './slices/student';
import parentReducer from './slices/parent';
import teacherReducer from './slices/teacher';
import commonReducer from './slices/common';
import profileReducer from './slices/profile';
import managmentReducer from './slices/managment';
import managmentWPReducer from './slices/managmentWP';
import commonWPReducer from './slices/commonWP';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    account: accountReducer,
    snackbar: snackbarReducer,
    menu: menuReducer,
    student: persistReducer(
        {
            key: 'student',
            storage,
            keyPrefix: 'pbx-'
        },
        studentReducer
    ),
    parent: persistReducer(
        {
            key: 'parent',
            storage,
            keyPrefix: 'pbx-'
        },
        parentReducer
    ),
    teacher: persistReducer(
        {
            key: 'teacher',
            storage,
            keyPrefix: 'pbx-'
        },
        teacherReducer
    ),
    common: persistReducer(
        {
            key: 'common',
            storage,
            keyPrefix: 'pbx-'
        },
        commonReducer
    ),
    profile: persistReducer(
        {
            key: 'profile',
            storage,
            keyPrefix: 'pbx-'
        },
        profileReducer
    ),
    managment: persistReducer(
        {
            key: 'managment',
            storage,
            keyPrefix: 'pbx-'
        },
        managmentReducer
    ),
    managmentWP: managmentWPReducer,
    commonWP: commonWPReducer
});

export default reducer;
