import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Parent Imports
const SecurityGaurdHome = Loadable(lazy(() => import('views/securityGaurd/Home')));
const OrganizationHome = Loadable(lazy(() => import('views/organization')));
const Account = Loadable(lazy(() => import('views/common/Account')));
const SchoolVisiter = Loadable(lazy(() => import('views/securityGaurd/SchoolVisiter')));

// ==============================|| MAIN ROUTING ||============================== //

const OrganizationRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <OrganizationHome />
        },
        {
            path: '/home/school-visiter',
            element: <SchoolVisiter />
        },
        {
            path: '/account',
            element: <Account />
        }
    ]
};

export default OrganizationRoutes;
