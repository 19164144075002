import { lazy } from 'react';

import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// ==============================|| MAIN ROUTING ||============================== //
const ConductorHome = Loadable(lazy(() => import('views/conductor')));
const Students = Loadable(lazy(() => import('views/conductor/SextNextForm/SextNextFormPage')));

const ConductorRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <ConductorHome />
        },
        {
            path: '/home/students',
            element: <Students />
        }
    ]
};

export default ConductorRoutes;
