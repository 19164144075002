import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import { Grid, Box, Typography } from '@mui/material';
import MuiTooltip from '@mui/material/Tooltip';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import './navigation.css';
import SelectedListItem from './SelectedListItem';
import { width } from '@mui/system';
import useAuth from 'hooks/useAuth';
import {
    Approval,
    CalendarMonthOutlined,
    CurrencyRupeeOutlined,
    DirectionsBus,
    InfoOutlined,
    ListAltOutlined,
    MenuBookOutlined,
    NotificationsNoneOutlined,
    PeopleAltOutlined
} from '@mui/icons-material';

export default function Home() {
    const { defaultRole } = useAuth();
    const ref = React.useRef(null);
    const [selectedMenu, setSelectedMenu] = React.useState();
    const location = useLocation();

    const [open, setOpen] = React.useState(false);

    const handleTooltipOpen = () => {
        setOpen(!open);
    };

    const handleMenuSelect = (menuName) => {
        setSelectedMenu(menuName);
        if (menuName !== 2) setOpen(false);
    };

    const handleMenuIcon = (iconName) => (
        <>
            {iconName === 'Home' && <HomeOutlinedIcon />}
            {(iconName === 'Info' || iconName === 'About PBX') && <InfoOutlined />}
            {iconName === 'Notice' && <NotificationsNoneOutlined />}
            {iconName === 'Fees' && <CurrencyRupeeOutlined />}
            {iconName === 'Classes' && <MenuBookOutlined />}
            {iconName === 'Students' && <ListAltOutlined />}
            {iconName === 'Children' && <PeopleAltOutlined />}
            {iconName === 'Attendance' && <CalendarMonthOutlined />}
            {iconName === 'Approval' && <Approval />}
            {iconName === 'Transport' && <DirectionsBus />}
        </>
    );

    const leftItem = [];
    const rightItem = [];

    if (defaultRole === 'managment') {
        leftItem.push({ leftName: 'Info', leftLink: '/home' });
        leftItem.push({ leftName: 'Classes', leftLink: '/classes' });
        rightItem.push({ rightName: 'Notice', rightLink: '/notice' });
        rightItem.push({ rightName: 'Fees', rightLink: '/fees' });
    }
    if (defaultRole === 'student') {
        leftItem.push({ leftName: 'Home', leftLink: '/home' });
        leftItem.push({ leftName: 'Classes', leftLink: '/classes' });
        rightItem.push({ rightName: 'Notice', rightLink: '/notice' });
        rightItem.push({ rightName: 'Attendance', rightLink: '/attendance' });
    }
    if (defaultRole === 'teacher') {
        leftItem.push({ leftName: 'Home', leftLink: '/home' });
        leftItem.push({ leftName: 'Students', leftLink: '/students' });
        rightItem.push({ rightName: 'Notice', rightLink: '/notice' });
        rightItem.push({ rightName: 'Attendance', rightLink: '/attendance' });
    }
    if (defaultRole === 'parent') {
        leftItem.push({ leftName: 'Home', leftLink: '/home' });
        leftItem.push({ leftName: 'Children', leftLink: '/children' });
        rightItem.push({ rightName: 'Approval', rightLink: '/approval' });
        rightItem.push({ rightName: 'Transport', rightLink: '/transport' });
    }
    if (defaultRole === 'anon' || defaultRole === 'free') {
        leftItem.push({ leftName: 'Home', leftLink: '/home' });
        leftItem.push({ leftName: 'About PBX', leftLink: '/aboutus' });
    }

    const itemTarget = '_self';
    const listItemProps = (link) => ({
        component: React.forwardRef((props, ref) => <Link ref={ref} {...props} to={link} target={itemTarget} />)
    });

    return (
        <Box ref={ref}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0
                        }}
                    >
                        <BottomNavigation
                            showLabels
                            sx={{
                                // backgroundColor: '#1c5694',
                                backgroundColor: '#3F51B5',
                                height: 52,
                                color: '#ffffff',
                                '& 	.MuiBottomNavigationAction-root': {
                                    minWidth: '20%',
                                    alignContent: 'center',
                                    display: 'flex',
                                    color: '#d6e2eb'
                                },
                                '& .Mui-selected': {
                                    bgcolor: '#bdc3e8',
                                    color: '#3F51B5'
                                }
                            }}
                            value={location.pathname === '/home' ? 0 : selectedMenu}
                            onChange={(e, newValue) => {
                                handleMenuSelect(newValue);
                            }}
                        >
                            {defaultRole !== 'securitygaurd' &&
                            defaultRole !== 'organization' &&
                            defaultRole !== 'accountant' &&
                            defaultRole !== 'conductor' ? (
                                <BottomNavigationAction
                                    {...listItemProps(leftItem[0]?.leftLink)}
                                    label={<Typography sx={{ fontSize: '0.70rem' }}>{leftItem[0]?.leftName}</Typography>}
                                    icon={handleMenuIcon(leftItem[0]?.leftName)}
                                />
                            ) : null}

                            {defaultRole !== 'securitygaurd' &&
                            defaultRole !== 'organization' &&
                            defaultRole !== 'accountant' &&
                            defaultRole !== 'conductor' ? (
                                <BottomNavigationAction
                                    {...listItemProps(leftItem[1]?.leftLink)}
                                    label={<Typography sx={{ fontSize: '0.70rem' }}>{leftItem[1]?.leftName}</Typography>}
                                    icon={handleMenuIcon(leftItem[1]?.leftName)}
                                />
                            ) : null}

                            {(defaultRole === 'managment' || defaultRole === 'student' || defaultRole === 'teacher') && (
                                <MuiTooltip
                                    PopperProps={{
                                        disablePortal: true
                                    }}
                                    onClose={() => handleTooltipOpen()}
                                    open={open}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    // title={<Paper>{menuList()}</Paper>}
                                    title={<SelectedListItem handleTooltipOpen={handleTooltipOpen} />}
                                    arrow
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                // bgcolor: '#1c5694',
                                                bgcolor: '#3F51B5',
                                                '& .MuiTooltip-arrow': {
                                                    // color: '#1c5694',
                                                    color: '#3F51B5',
                                                    height: 70,
                                                    width: 99,
                                                    // top: 115,
                                                    // bottom: 0
                                                    marginBottom: '-70px !important'
                                                },
                                                marginBottom: '12px !important',
                                                padding: '7px 7px',
                                                width: 150
                                            }
                                        }
                                    }}
                                >
                                    <BottomNavigationAction
                                        sx={{
                                            justifyContent: 'center',
                                            zIndex: 1501
                                        }}
                                        label={<Typography sx={{ fontSize: '0.70rem' }}>Apps</Typography>}
                                        icon={<AppsIcon />}
                                        onClick={() => handleTooltipOpen()}
                                    />
                                </MuiTooltip>
                            )}
                            {(defaultRole === 'managment' ||
                                defaultRole === 'student' ||
                                defaultRole === 'teacher' ||
                                defaultRole === 'parent') && (
                                <BottomNavigationAction
                                    {...listItemProps(rightItem[0]?.rightLink)}
                                    label={<Typography sx={{ fontSize: '0.70rem' }}>{rightItem[0]?.rightName}</Typography>}
                                    icon={handleMenuIcon(rightItem[0]?.rightName)}
                                />
                            )}
                            {(defaultRole === 'managment' ||
                                defaultRole === 'student' ||
                                defaultRole === 'teacher' ||
                                defaultRole === 'parent') && (
                                <BottomNavigationAction
                                    {...listItemProps(rightItem[1]?.rightLink)}
                                    label={<Typography sx={{ fontSize: '0.70rem' }}>{rightItem[1]?.rightName}</Typography>}
                                    icon={handleMenuIcon(rightItem[1]?.rightName)}
                                />
                            )}

                            {/* )} */}
                        </BottomNavigation>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
