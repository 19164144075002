import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';
import App from 'App';
import { useDispatch } from 'store';
import { setAolloHeaders } from 'store/slices/common';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn, defaultRole, user } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let role;

    if (defaultRole === 'student') {
        role = 'pbxstudentplus';
    } else if (defaultRole === 'parent') {
        role = 'pbxguardianfree';
    } else if (defaultRole === 'teacher') {
        role = 'pbxteacherfree';
    } else if (defaultRole === 'managment') {
        role = 'pbxmgmtfree';
    } else if (defaultRole === 'conductor') {
        role = 'pbxcondfree';
    } else if (defaultRole === 'securitygaurd') {
        role = 'pbxsecufree';
    } else if (defaultRole === 'accountant') {
        role = 'pbxaccnfree';
    } else if (defaultRole === 'organization') {
        role = 'pbxorgfree';
    }

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(setAolloHeaders({ idToken: user.idToken, role, uid: user.id }));
            navigate(DASHBOARD_PATH, { replace: true });
        }
    }, [isLoggedIn, navigate]);

    return <>{children}</>;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
