// routing
import Routes from 'routes';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { useSelector } from 'store';

const createApolloClient = (apolloHeader, isDummy) => {
    let client;
    if (!isDummy) {
        client = new ApolloClient({
            link: new HttpLink({
                uri: 'https://hsr-pbx-v1-axc73sapwa-em.a.run.app/v1/graphql',
                headers: {
                    'content-type': 'application/json',
                    'X-Hasura-Role': apolloHeader.role,
                    'X-Hasura-User-Id': apolloHeader.uid,
                    Authorization: `Bearer ${apolloHeader.idToken}`
                }
            }),
            cache: new InMemoryCache()
        });
    } else if (isDummy) {
        client = new ApolloClient({
            link: new HttpLink({
                uri: 'https://hsr-pbx-v1-axc73sapwa-em.a.run.app/v1/graphql'
            }),
            cache: new InMemoryCache()
        });
    }

    return client;
};

const App = () => {
    const { apolloHeader, isDummy } = useSelector((state) => state.common);
    const client = createApolloClient(apolloHeader, isDummy);
    return (
        <ApolloProvider client={client}>
            <ThemeCustomization>
                {/* RTL layout */}
                <RTLLayout>
                    <Locales>
                        <NavigationScroll>
                            <AuthProvider>
                                <>
                                    <Routes />
                                    <Snackbar />
                                </>
                            </AuthProvider>
                        </NavigationScroll>
                    </Locales>
                </RTLLayout>
            </ThemeCustomization>
        </ApolloProvider>
    );
};

export default App;
