import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    teacherDashData: {},
    teacherClassStudents: [],
    teacherGaurdianDetails: [],
    teacherParentDetails: [],
    teacherNotice: [],
    teacherExam: []
};

export const teacherSlice = createSlice({
    name: 'teacher',
    initialState,
    reducers: {
        setTeacherDashData: (state, action) => {
            state.teacherDashData = action.payload;
        },
        setTeacherClassStudents: (state, action) => {
            state.teacherClassStudents = action.payload;
        },
        setTeacherGaurdianDetails: (state, action) => {
            state.teacherGaurdianDetails = action.payload;
        },
        setTeacherParentDetails: (state, action) => {
            state.teacherParentDetails = action.payload;
        },
        setTeacherNotice: (state, action) => {
            state.teacherNotice = action.payload;
        },
        setTeacherExam: (state, action) => {
            state.teacherExam = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    setTeacherDashData,
    setTeacherClassStudents,
    setTeacherGaurdianDetails,
    setTeacherParentDetails,
    setTeacherNotice,
    setTeacherExam
} = teacherSlice.actions;

export default teacherSlice.reducer;
