import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    basicInfo: {},
    localAddress: {},
    permanentAddress: {},
    fatherDetails: {},
    motherDetails: {},
    accessControl: {
        schoolAccess: '',
        companyAccess: '',
        gaurdianMailIds: {},
        guardianAccessId: ''
    }
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setBasicInfo: (state, action) => {
            state.basicInfo = action.payload;
        },
        setLocalAddress: (state, action) => {
            state.localAddress = action.payload;
        },
        setPermanentAddress: (state, action) => {
            state.permanentAddress = action.payload;
        },
        setFatherDetails: (state, action) => {
            state.fatherDetails = action.payload;
        },
        setMotherDetails: (state, action) => {
            state.motherDetails = action.payload;
        },
        setAccessControl: (state, action) => {
            if (action.payload.schoolAccess) {
                state.accessControl.schoolAccess = action.payload.schoolAccess?.school_access;
                state.accessControl.guardianAccessId = action.payload.schoolAccess?.guardian_access;
            } else if (action.payload.companyAccess) {
                state.accessControl.companyAccess = action.payload.companyAccess;
            } else if (action.payload.gaurdianMailIds) {
                state.accessControl.gaurdianMailIds = action.payload.gaurdianMailIds;
            }
        }
    }
});

// Action creators are generated for each case reducer function
export const { setBasicInfo, setLocalAddress, setPermanentAddress, setFatherDetails, setMotherDetails, setAccessControl } =
    profileSlice.actions;

export default profileSlice.reducer;
