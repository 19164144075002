/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const USER_INFO_FB = gql`
    query UserInfoForFB($userId: String!) {
        user_info_by_pk(guid: $userId) {
            guid
        }
    }
`;

export const INSERT_STUDENT_INTIAL_DATA_CREATION = gql`
    mutation insertStudentIntitalDataCreation(
        $userId: String!
        $planName: String
        $subEnd: Int
        $schoolAccess: String
        $comapnyAccess: String
    ) {
        insert_user_info_one(
            object: { guid: $userId, plan_name: $planName, sub_end: $subEnd, school_access: $schoolAccess, company_access: $comapnyAccess }
        ) {
            guid
        }
    }
`;

export const UPDATE_STUDENT_INTIAL_DATA_CREATION = gql`
    mutation updateStudentIntitalDataCreation($userId: String!, $planName: String, $subEnd: Int) {
        update_user_info_by_pk(pk_columns: { guid: $userId }, _set: { plan_name: $planName, sub_end: $subEnd }) {
            guid
        }
    }
`;

export const GET_SCHOOL_SEARCH_INITIAL = gql`
    query SchoolSearchInitial {
        school_info(limit: 10, order_by: { created_at: asc }) {
            gusid
            school_name
            class_upto
            establish_date
            fee_start
            principle_name
            principle_photo
            school_medium
            school_phone
        }
    }
`;

export const GET_SCHOOL_SEARCH_BY_DISTRICT_AND_GROUP = gql`
    query getSchoolSearchByDistrictAndGroup($district: String, $affliatedTo: String, $schoolMedium: String, $schoolFor: String) {
        school_info(
            limit: 10
            where: {
                school_address: { district: { _eq: $district } }
                affliated_to: { _eq: $affliatedTo }
                school_medium: { _eq: $schoolMedium }
                school_for: { _eq: $schoolFor }
            }
            order_by: { created_at: asc }
        ) {
            gusid
            school_name
            class_upto
            establish_date
            fee_start
            principle_name
            principle_photo
            school_medium
            school_phone
        }
    }
`;

export const GET_SCHOOL_SEARCH_BY_PINCODE_AND_GROUP = gql`
    query getSchoolSearchByPincodeAndGroup($pinCode: String, $affliatedTo: String, $schoolMedium: String, $schoolFor: String) {
        school_info(
            limit: 10
            where: {
                school_address: { pin_zip_code: { _eq: $pinCode } }
                affliated_to: { _eq: $affliatedTo }
                school_medium: { _eq: $schoolMedium }
                school_for: { _eq: $schoolFor }
            }
            order_by: { created_at: asc }
        ) {
            gusid
            school_name
            class_upto
            establish_date
            fee_start
            principle_name
            principle_photo
            school_medium
            school_phone
        }
    }
`;
