import { useLazyQuery } from '@apollo/client';
import { createSlice } from '@reduxjs/toolkit';
import { GET_SCHOOL_SEARCH_INITIAL } from 'services/commonGqlFb';
import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    commonNotices: {},
    commonExams: [],
    states: [],
    error: null,
    cities: [],
    findSchoolsList: [],
    selectedAccordianId: null,
    isUpdated: false,
    formatedDocList: [],
    isRecordInserted: false,
    isSubjectOfExamUpdated: false,
    selectedSubIdOfSubjectOfExam: '',
    selectedMobileMenuItem: 0,
    isDummy: false,
    apolloHeader: {},
    isStudentDetailsEmpty: true,
    page: 0,
    rowsPerPage: 10,
    tableData: [],
    filteredTableData: []
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setCommonNotices: (state, action) => {
            state.commonNotices = action.payload;
        },
        setCommonExams: (state, action) => {
            state.commonExams = action.payload;
        },
        setStates: (state, action) => {
            state.states = action.payload;
        },
        setCities: (state, action) => {
            state.cities = action.payload;
        },
        hasError(state, action) {
            state.error = action.payload;
        },
        setFindSchoolsList(state, action) {
            state.findSchoolsList = action.payload;
        },
        setSelectedAccordianId(state, action) {
            state.selectedAccordianId = action.payload;
        },
        setIsUpdated: (state, action) => {
            state.isUpdated = action.payload;
        },
        setFormatedDocList: (state, action) => {
            state.formatedDocList = action.payload;
        },
        setIsRecordInserted: (state, action) => {
            state.isRecordInserted = action.payload;
        },
        setIsSubjectOfExamUpdated: (state, action) => {
            state.isSubjectOfExamUpdated = action.payload;
        },
        setSelectedSubIdOfSubjectOfExam: (state, action) => {
            state.selectedSubIdOfSubjectOfExam = action.payload;
        },
        setSelectedMobileMenuItem: (state, action) => {
            state.selectedMobileMenuItem = action.payload;
        },
        setIsDummy: (state, action) => {
            state.isDummy = action.payload;
        },
        setAolloHeaders: (state, action) => {
            state.apolloHeader = action.payload;
        },
        setIsStudentDetailsEmpty: (state, action) => {
            state.isStudentDetailsEmpty = action.payload;
        },

        setPage: (state, action) => {
            state.page = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setTableData: (state, action) => {
            state.tableData = action.payload;
        },
        setFilteredTableData: (state, action) => {
            state.filteredTableData = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    setCommonNotices,
    setCommonExams,
    setFindSchoolsList,
    setSelectedAccordianId,
    setIsUpdated,
    setFormatedDocList,
    setIsRecordInserted,
    setIsSubjectOfExamUpdated,
    setSelectedSubIdOfSubjectOfExam,
    setSelectedMobileMenuItem,
    setIsDummy,
    setAolloHeaders,
    setIsStudentDetailsEmpty,
    setPage,
    setRowsPerPage,
    setTableData,
    setFilteredTableData
} = commonSlice.actions;

export default commonSlice.reducer;

export function setStates() {
    return async () => {
        try {
            const response = await axios.get('https://api.countrystatecity.in/v1/countries/IN/states', {
                headers: {
                    'X-CSCAPI-KEY': 'QzdNejlFS3RGdnhGSEdaRTRhQ1dmVlRuQ2p4MHM5WFJaeWdzR1E5TA=='
                }
            });
            dispatch(commonSlice.actions.setStates(response.data));
        } catch (error) {
            dispatch(commonSlice.actions.hasError(error));
        }
    };
}

export function setCities(stateCode) {
    return async () => {
        try {
            const response = await axios.get(`https://api.countrystatecity.in/v1/countries/IN/states/${stateCode}/cities`, {
                headers: {
                    'X-CSCAPI-KEY': 'QzdNejlFS3RGdnhGSEdaRTRhQ1dmVlRuQ2p4MHM5WFJaeWdzR1E5TA=='
                }
            });
            dispatch(commonSlice.actions.setCities(response.data));
        } catch (error) {
            dispatch(commonSlice.actions.hasError(error));
        }
    };
}
