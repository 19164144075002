// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    HomeOutlined,
    MenuBookOutlined,
    NotificationsNoneOutlined,
    CalendarMonthOutlined,
    RateReviewOutlined,
    InventoryOutlined,
    ListAltOutlined,
    CurrencyRupeeOutlined
} from '@mui/icons-material';

// constant
const icons = {
    HomeOutlined,
    MenuBookOutlined,
    NotificationsNoneOutlined,
    RateReviewOutlined,
    InventoryOutlined,
    CalendarMonthOutlined,
    ListAltOutlined,
    CurrencyRupeeOutlined
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const teacher = {
    id: 'teacher',
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/home',
            icon: icons.HomeOutlined,
            breadcrumbs: false
        },
        {
            id: 'students',
            title: <FormattedMessage id="students" />,
            type: 'item',
            url: '/students',
            icon: icons.ListAltOutlined,
            breadcrumbs: false
        },
        {
            id: 'notice',
            title: <FormattedMessage id="notice" />,
            type: 'item',
            url: '/notice',
            icon: icons.NotificationsNoneOutlined,
            breadcrumbs: false
        },
        {
            id: 'attendance',
            title: <FormattedMessage id="attendance" />,
            type: 'item',
            url: '/attendance',
            icon: icons.CalendarMonthOutlined,
            breadcrumbs: false
        },
        {
            id: 'exams',
            title: <FormattedMessage id="exams" />,
            type: 'item',
            url: '/exams',
            icon: icons.RateReviewOutlined,
            breadcrumbs: false
        },
        {
            id: 'results',
            title: <FormattedMessage id="results" />,
            type: 'item',
            url: '/results',
            icon: icons.InventoryOutlined,
            breadcrumbs: false
        },
        {
            id: 'fees',
            title: <FormattedMessage id="Fees" />,
            type: 'item',
            url: '/fees',
            icon: icons.CurrencyRupeeOutlined,
            breadcrumbs: false
        }
    ]
};

export default teacher;
