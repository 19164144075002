import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Teacher Imports
const TeacherHome = Loadable(lazy(() => import('views/teacher/Home')));
const TeacherStudents = Loadable(lazy(() => import('views/teacher/Students')));
const TeacherExams = Loadable(lazy(() => import('views/teacher/Exams')));
const TeacherResults = Loadable(lazy(() => import('views/teacher/Results')));
const TeacherNotice = Loadable(lazy(() => import('views/teacher/Notice')));
const TeacherAttendance = Loadable(lazy(() => import('views/teacher/Attendance')));
const Profile = Loadable(lazy(() => import('views/common/Profile')));
const Account = Loadable(lazy(() => import('views/common/Account')));
const TeacherTakeAttendance = Loadable(lazy(() => import('views/teacher/Attendance/TakeAttendance')));
const TeacherSubAndMark = Loadable(lazy(() => import('views/teacher/Results/ResultSubAndMark')));
const FeesDetails = Loadable(lazy(() => import('views/managment/Fees/FeesStatusByMonths')));

// ==============================|| MAIN ROUTING ||============================== //

const TeacherRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <TeacherHome />
        },
        {
            path: '/students',
            element: <TeacherStudents />
        },
        {
            path: '/exams',
            element: <TeacherExams />
        },
        {
            path: '/results',
            element: <TeacherResults />
        },
        {
            path: '/notice',
            element: <TeacherNotice />
        },
        {
            path: '/attendance',
            element: <TeacherAttendance />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/attendance/take-attendance',
            element: <TeacherTakeAttendance />
        },
        {
            path: '/results/subject-and-marks',
            element: <TeacherSubAndMark />
        },
        {
            path: '/fees',
            element: <FeesDetails componentType="Teacher" />
        }
    ]
};

export default TeacherRoutes;
