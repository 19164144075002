import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    parentHomeChildren: {},
    parentHomeNotice: {},
    parentHomeExam: {},
    parentChildrens: []
};

export const parentSlice = createSlice({
    name: 'teacher',
    initialState,
    reducers: {
        setParentHomeChildren: (state, action) => {
            state.parentHomeChildren = action.payload;
        },
        setParentHomeNotice: (state, action) => {
            state.parentHomeNotice = action.payload;
        },
        setParentHomeExam: (state, action) => {
            state.parentHomeExam = action.payload;
        },
        setParentChildrens: (state, action) => {
            state.parentChildrens = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setParentHomeChildren, setParentHomeNotice, setParentHomeExam, setParentChildrens } = parentSlice.actions;

export default parentSlice.reducer;
