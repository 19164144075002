import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    studentDetails: {},
    studentNotice: [],
    studentDashNotice: [],
    studentExams: [],
    studentResultDetails: {},
    studentAttendance: {},
    attendanceMonths: [],
    attendanceDays: [],
    sundaysOfTheMonth: [],
    studentClasses: [],
    attendanceSummary: {
        thisMPCount: 0,
        thisMPPercantage: 0,
        thisYPCount: 0,
        thisYPPercentage: 0
    }
};

export const studentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        setStudentDetails: (state, action) => {
            state.studentDetails = action.payload;
        },
        setStudentDashNotice: (state, action) => {
            state.studentDashNotice = action.payload;
        },
        setStudentNotice: (state, action) => {
            state.studentNotice = action.payload;
        },
        setStudentExams: (state, action) => {
            state.studentExams = action.payload;
        },
        setStudentResultDetails: (state, action) => {
            state.studentResultDetails = action.payload;
        },
        setStudentAttendance: (state, action) => {
            state.studentAttendance = action.payload;
        },
        setAttendanceMonths: (state, action) => {
            state.attendanceMonths = action.payload;
        },
        setAttendanceDays: (state, action) => {
            state.attendanceDays = action.payload;
        },
        setSundaysOfTheMonth: (state, action) => {
            state.sundaysOfTheMonth = action.payload;
        },
        setStudentClasses: (state, action) => {
            state.studentClasses = action.payload;
        },
        setAttendanceSummary: (state, action) => {
            state.attendanceSummary = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    setStudentDetails,
    setStudentNotice,
    setStudentDashNotice,
    setStudentExams,
    setStudentResultDetails,
    setStudentAttendance,
    setAttendanceMonths,
    setAttendanceDays,
    setSundaysOfTheMonth,
    setStudentClasses,
    setAttendanceSummary
} = studentSlice.actions;

export default studentSlice.reducer;
