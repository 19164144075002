/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';
// import {
//     setNewClassAction,
//     setNewStaffAction,
//     setNewStudentClassDetailsAction,
//     setNewStudentDetailsAction,
//     setNewStudentFDetailsAction,
//     setNewStudentGDetailsAction,
//     setNewStudentMDetailsAction,
//     setNewTeacherAction,
//     setRecordClassesListAction,
//     setRecordStaffsListAction,
//     setRecordStudentsListAction,
//     setRecordTeachersListAction
// } from 'store/actions/managmentActions';

const initialState = {
    newClass: {},
    newTeacher: {},
    newStaff: {},
    newStudentDetails: {},
    newStudentFDetails: {},
    newStudentMDetails: {},
    newStudentGDetails: {},
    newStudentClassDetails: {},
    recordClassesList: [],
    recordTeachersList: [],
    recordStaffsList: [],
    recordStudentsList: []
};

export const managmentSlice = createSlice({
    name: 'managment',
    initialState,
    reducers: {
        setNewClass: (state, action) => {
            state.newClass = action.payload;
        },
        setNewTeacher: (state, action) => {
            state.newTeacher = action.payload;
        },
        setNewStaff: (state, action) => {
            state.newStaff = action.payload;
        },
        setNewStudentDetails: (state, action) => {
            state.newStudentDetails = action.payload;
        },
        setNewStudentFDetails: (state, action) => {
            state.newStudentFDetails = action.payload;
        },
        setNewStudentMDetails: (state, action) => {
            state.newStudentMDetails = action.payload;
        },
        setNewStudentGDetails: (state, action) => {
            state.newStudentGDetails = action.payload;
        },
        setNewStudentClassDetails: (state, action) => {
            state.newStudentClassDetails = action.payload;
        },
        setRecordClassesList: (state, action) => {
            state.recordClassesList = action.payload;
        },
        setRecordTeachersList: (state, action) => {
            state.recordTeachersList = action.payload;
        },
        setRecordStaffsList: (state, action) => {
            state.recordStaffsList = action.payload;
        },
        setRecordStudentsList: (state, action) => {
            state.recordStudentsList = action.payload;
        }
    }
});

export const {
    setNewClass,
    setNewTeacher,
    setNewStaff,
    setNewStudentDetails,
    setNewStudentFDetails,
    setNewStudentMDetails,
    setNewStudentGDetails,
    setNewStudentClassDetails,
    setRecordClassesList,
    setRecordTeachersList,
    setRecordStaffsList,
    setRecordStudentsList
} = managmentSlice.actions;

export default managmentSlice.reducer;
