import { lazy } from 'react';

import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// ==============================|| MAIN ROUTING ||============================== //
const AccountantHome = Loadable(lazy(() => import('views/accountant')));
const TutionFeeHome = Loadable(lazy(() => import('views/accountant/AccordionPage/AccordionForm')));
const FeeDetailsHome = Loadable(lazy(() => import('views/accountant/AccordionPage/SextNextPage/SextNextSextPage')));

const AccountantRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <AccountantHome />
        },
        {
            path: '/tution-fee',
            element: <TutionFeeHome />
        },
        {
            path: '/tution/feedetails',
            element: <FeeDetailsHome />
        }
    ]
};

export default AccountantRoutes;
