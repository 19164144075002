import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { getDatabase } from 'firebase/database';

// action - state management
import { DEFAULT_ROLE, LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { FIREBASE_API } from 'config';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { capitalize, isEmpty, replace } from 'lodash';
import { closeSnackbar } from 'store/slices/snackbar';
import { INSERT_STUDENT_INTIAL_DATA_CREATION, UPDATE_STUDENT_INTIAL_DATA_CREATION, USER_INFO_FB } from 'services/commonGqlFb';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { customAlphabet, nanoid } from 'nanoid';
import { getStorage } from 'firebase/storage';
import { useDispatch } from 'store';
import { setIdToken } from 'store/slices/commonWP';

// firebase initialize
let storage;
if (!firebase.apps.length) {
    const app = firebase.initializeApp(FIREBASE_API);
    storage = getStorage(app);
}

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    defaultRole: 'anon'
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

const filterUserType = (roles) => {
    let role = 'free';
    const userRoles = [];

    // eslint-disable-next-line array-callback-return
    roles?.map((item) => {
        if (item?.uT === 'pbxstudentplus') {
            role = 'student';
            userRoles.push(capitalize(role));
        } else if (item === 'pbxguardianfree') {
            role = 'parent';
            userRoles.push(capitalize(role));
        } else if (item === 'pbxteacherfree') {
            role = 'teacher';
            userRoles.push(capitalize(role));
        } else if (item === 'pbxmgmtfree') {
            role = 'managment';
            userRoles.push(capitalize(role));
        } else if (item === 'pbxcondfree') {
            role = 'conductor';
            userRoles.push(capitalize(role));
        } else if (item === 'pbxsecufree') {
            role = 'securitygaurd';
            userRoles.push(capitalize(role));
        } else if (item === 'pbxaccnfree') {
            role = 'accountant';
            userRoles.push(capitalize(role));
        } else if (item === 'pbxorgfree') {
            role = 'organization';
            userRoles.push(capitalize(role));
        }
    });
    return { role, userRoles: !isEmpty(userRoles) ? [...userRoles, 'Organization'] : ['free'] };
    // return { role, userRoles: !isEmpty(userRoles) ? userRoles : ['parent'] };
};

const randomNo = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 8);

export const FirebaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const navigate = useNavigate();
    const [isRecordUpdated, setRecordUpdated] = useState(false);

    const [getUserInfoGuid] = useLazyQuery(USER_INFO_FB);

    const [insertLocalAddress] = useMutation(INSERT_STUDENT_INTIAL_DATA_CREATION);

    const [updateLocalAddress] = useMutation(UPDATE_STUDENT_INTIAL_DATA_CREATION);

    // const [getUserInfoGuid, { loading: getUserInfoGuidLoading, error: getUserInfoGuidError, data: getUserInfoGuidData }] =
    //     useLazyQuery(USER_INFO_FB);

    // const [insertLocalAddress, { data: insertUserInitialData, error: insertUserInitialDataerror, loading: insertUserInitialDataloading }] =
    //     useMutation(INSERT_STUDENT_INTIAL_DATA_CREATION);

    // const [updateLocalAddress, { data: updateUserInitialData, error: updateUserInitialDataerror, loading: updateUserInitialDataloading }] =
    //     useMutation(UPDATE_STUDENT_INTIAL_DATA_CREATION);

    // useEffect(() => {
    //     if (getUserInfoGuidLoading) {
    //
    //     } else if (getUserInfoGuidError) {
    //
    //     } else if (getUserInfoGuidData) {
    //
    //     }
    // }, [getUserInfoGuidLoading, getUserInfoGuidError, getUserInfoGuidData]);

    // useEffect(() => {
    //     if (insertUserInitialData) {
    //
    //     }
    //     if (insertUserInitialDataerror) {
    //
    //     }
    // }, [insertUserInitialData, insertUserInitialDataerror]);

    // useEffect(() => {
    //     if (updateUserInitialData) {
    //
    //     }
    //     if (updateUserInitialDataerror) {
    //
    //     }
    // }, [updateUserInitialData, updateUserInitialDataerror]);

    const handleRole = (role, isDisabledRedirectToHome = false) => {
        const selectedRole = role === 'security guard' ? 'securitygaurd' : role;

        // const selectedRole = 'parent';

        localStorage.setItem('selctedRole', selectedRole);
        dispatch({
            type: DEFAULT_ROLE,
            payload: {
                role: selectedRole || 'anon'
            }
        });
        if (!isDisabledRedirectToHome) {
            navigate('/home', { replace: true });
        }
    };

    useEffect(() => {
        let callback = null;
        let metadataRef = null;
        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
            // Remove previous listener.
            if (callback) {
                metadataRef.off('value', callback);
            }
            // On user login add new listener.
            if (user) {
                // Check if refresh is required.
                const idToken = await user.getIdToken(true);
                localStorage.setItem('idToken', idToken);

                metadataRef = firebase.database().ref(`metadata/${user.uid}/refreshTime`);
                callback = () => {
                    user.getIdTokenResult().then(async (idTokenResult) => {
                        const roles = [
                            idTokenResult.claims.pbx,
                            idTokenResult.claims.teach,
                            idTokenResult.claims.mgmt,
                            idTokenResult.claims.prnt,
                            idTokenResult.claims.cond,
                            idTokenResult.claims.secu,
                            idTokenResult.claims.accn
                        ];
                        const userType = filterUserType(roles);

                        if (idTokenResult.claims.planName) {
                            const resUserInfoGuid = await getUserInfoGuid({
                                variables: {
                                    userId: user.uid
                                },
                                fetchPolicy: 'network-only'
                            });
                            if (resUserInfoGuid.data.user_info_by_pk?.guid === user.uid) {
                                await updateLocalAddress({
                                    variables: {
                                        userId: user.uid,
                                        planName: idTokenResult.claims.planName,
                                        subEnd: idTokenResult.claims.dueDate
                                    },
                                    fetchPolicy: 'network-only'
                                });
                            } else {
                                await insertLocalAddress({
                                    variables: {
                                        userId: user.uid,
                                        planName: idTokenResult.claims.planName,
                                        subEnd: idTokenResult.claims.dueDate,
                                        schoolAccess: randomNo(),
                                        comapnyAccess: randomNo()
                                    },
                                    fetchPolicy: 'network-only'
                                });
                            }
                        }
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: false,
                                user: {
                                    id: user.uid,
                                    email: user.email,
                                    name: user.displayName || 'John Doe',
                                    isEmailVerfied: user.emailVerified,
                                    userData: user,
                                    planName: idTokenResult.claims.planName ? idTokenResult.claims?.planName : 'Free',
                                    userRoles: userType.userRoles,
                                    dueDate: idTokenResult.claims.dueDate,
                                    idToken
                                }
                            }
                        });
                        handleRole(userType.role, true);
                    });
                };
                // Subscribe new listener to changes on that node.
                user.getIdTokenResult().then((idTokenResult) => {
                    if (!idTokenResult.claims.planName) {
                        metadataRef.on('value', callback);
                    }
                });

                user.getIdTokenResult().then((idTokenResult) => {
                    const roles = [
                        idTokenResult.claims.pbx,
                        idTokenResult.claims.teach,
                        idTokenResult.claims.mgmt,
                        idTokenResult.claims.prnt,
                        idTokenResult.claims.cond,
                        idTokenResult.claims.secu,
                        idTokenResult.claims.accn
                    ];
                    const userType = filterUserType(roles);
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: false,
                            user: {
                                id: user.uid,
                                email: user.email,
                                name: user.displayName || 'Guest',
                                isEmailVerfied: user.emailVerified,
                                userData: user,
                                planName: idTokenResult.claims.planName ? idTokenResult.claims?.planName : 'Free',
                                userRoles: userType.userRoles,
                                dueDate: idTokenResult.claims.dueDate,
                                idToken
                            }
                        }
                    });
                    handleRole(userType.role, true);
                });
            } else {
                dispatch({
                    type: LOGOUT
                });
            }
        });

        return () => unsubscribe();
    }, [isRecordUpdated]);

    const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const logout = async () => {
        let isLogedOut;
        await firebase
            .auth()
            .signOut()
            .then(() => {
                isLogedOut = true;
            })
            .catch((error) => {
                isLogedOut = false;
            });

        return isLogedOut;
    };

    const resetPassword = async (email) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    const updateProfile = () => {};
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    const updateDisplayName = async (displayName) => {
        await firebase
            .auth()
            .currentUser.updateProfile({
                displayName
            })
            .then(() => {
                setRecordUpdated(!isRecordUpdated);
                return true;
            })
            .catch(() => false);
    };

    const updateEmail = async (email) => {
        await firebase
            .auth()
            .currentUser.updateEmail(email)
            .then(() => true)
            .catch(() => false);
    };

    const changePassword = async (newPwd) => {
        await firebase
            .auth()
            .currentUser.updatePassword(newPwd)
            .then(() => true)
            .catch(() => false);
    };

    const getInitStorage = () => {
        if (state.isInitialized) {
            return storage;
        }
        return null;
    };

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => {},
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile,
                handleRole,
                updateDisplayName,
                updateEmail,
                changePassword,
                getInitStorage
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
