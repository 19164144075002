import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Parent Imports
const AnonUserHome = Loadable(lazy(() => import('views/anon-user/Home')));
const AnonUserSchools = Loadable(lazy(() => import('views/anon-user/Schools')));
const Profile = Loadable(lazy(() => import('views/common/Profile')));
const Account = Loadable(lazy(() => import('views/common/Account')));

// ==============================|| MAIN ROUTING ||============================== //

const AnonymousUserRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <AnonUserHome />
        },
        {
            path: '/aboutus',
            element: <AnonUserSchools />
        },
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/profile',
            element: <Profile />
        }
    ]
};

export default AnonymousUserRoutes;
