import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Parent Imports
const ParentHome = Loadable(lazy(() => import('views/parent/Home')));
const ParentChildren = Loadable(lazy(() => import('views/parent/Children')));

// Student Imports
const StudentClasses = Loadable(lazy(() => import('views/student/Classes')));
const StudentNotice = Loadable(lazy(() => import('views/student/Notice')));
const StudentExams = Loadable(lazy(() => import('views/student/Exams')));
const StudentResults = Loadable(lazy(() => import('views/student/Result')));
const StudentAttendance = Loadable(lazy(() => import('views/student/Attendance')));
const StudentViewAttendance = Loadable(lazy(() => import('views/student/Attendance/user-attendanceApplication')));
const StudentFindSchool = Loadable(lazy(() => import('views/common/FindSchool')));
const StudentDetailsAndExamResult = Loadable(lazy(() => import('views/student/Result/user-activityhistorytable')));
const Profile = Loadable(lazy(() => import('views/common/Profile')));
const Account = Loadable(lazy(() => import('views/common/Account')));
const FeesDetails = Loadable(lazy(() => import('views/student/Fees')));
const SubjectsDetails = Loadable(lazy(() => import('views/student/Subjects')));
const Approval = Loadable(lazy(() => import('views/parent/Approval')));
const Visitors = Loadable(lazy(() => import('views/parent/Approval/Visitors')));
const Transport = Loadable(lazy(() => import('views/parent/Transport')));
const TransportDetails = Loadable(lazy(() => import('views/parent//Transport/TransportDetails')));
// ==============================|| MAIN ROUTING ||============================== //

const ParentRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <ParentHome />
        },
        {
            path: '/children',
            element: <ParentChildren />
        },
        {
            path: '/children/classes',
            element: <StudentClasses />
        },
        {
            path: '/children/notice',
            element: <StudentNotice />
        },
        {
            path: '/children/exam',
            element: <StudentExams />
        },
        {
            path: '/children/result',
            element: <StudentResults />
            // children: [
            //     {
            //         path: ':resultId',
            //         element: <StudentDetailsAndExamResult />
            //     }
            // ]
        },
        {
            path: 'children/result/view-result',
            element: <StudentDetailsAndExamResult />
        },
        {
            path: '/children/attendance',
            element: <StudentAttendance />
        },
        {
            path: '/children/attendance/view-attendance',
            element: <StudentViewAttendance />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/children/fees',
            element: <FeesDetails />
        },
        {
            path: '/children/subjects',
            element: <SubjectsDetails />
        },
        {
            path: '/approval',
            element: <Approval />
        },
        {
            path: '/approval/visitors',
            element: <Visitors />
        },
        {
            path: '/transport',
            element: <Transport />
        },
        {
            path: '/transport/transportdetails',
            element: <TransportDetails />
        }
        // ,
        // {
        //     path: '/findschool',
        //     element: <StudentFindSchool />
        // }
    ]
};

export default ParentRoutes;
