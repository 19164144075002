/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    studentAndPDetails: {},
    recordStudentsListWP: [],
    recordClassesListWP: []
};

export const managmentWPSlice = createSlice({
    name: 'managment',
    initialState,
    reducers: {
        setStudentAndPDetails: (state, action) => {
            state.studentAndPDetails = action.payload;
        },
        setRecordStudentsListWP: (state, action) => {
            state.recordStudentsListWP = action.payload;
        },
        setRecordClassesListWP: (state, action) => {
            state.recordClassesListWP = action.payload;
        }
    }
});

export const { setStudentAndPDetails, setRecordStudentsListWP, setRecordClassesListWP } = managmentWPSlice.actions;

export default managmentWPSlice.reducer;
